.fs-11 {
  font-size: 11px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}
