@import "typography";

.header {
  background-color: $orange;
  @extend .fs-13;
}

.title {
  padding-left: 5px;
}
